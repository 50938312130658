import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`yoga`, `kids`]} />
    <img style={{ margin: 0 }} src="./petit-yogi-logo.svg" alt="Petit Yogi" />
    <p>
      Our website is currently under construction. Meanwhile, you can contact us
      via Facebook
    </p>
    <a href="https://www.facebook.com/petityoginl/">
      <Button marginTop="35px">Visit our Facebook page</Button>
    </a>
  </Layout>
)

export default IndexPage
